<template>
  <div class="footer">
    <div class="auPageCenter">
      <el-row>
        <el-col :span="12" class="rightLine">
          <el-row>
            <el-col
              class="gap"
              v-for="(col, index) in links"
              :key="index"
              :span="24 / links.length"
            >
              <h3>{{ col.title }}</h3>
              <ul class="gap">
                <li v-for="(link, linkIndex) in col.list" :key="linkIndex">
                  <a
                    :href="link.href || 'javascript:;'"
                    @click="onChangeLink(link)"
                    >{{ link.title }}</a
                  >
                </li>
              </ul>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col
              v-for="(it, index) in codes"
              :key="index"
              :span="24 / codes.length"
              class="codePage"
            >
              <div class="code">
                <img :src="it.src" />
              </div>
              <div class="title">{{ it.title }}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="footers">
        Copyright © 2014-2021 土豆查 | 四川土豆查网络科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备2021011060号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: [
        {
          src: require("@/assets/ercode.png"),
          title: "客服微信",
        },
      ],
      links: [
        {
          title: "功能介绍",
          list: [
            {
              title: "功能介绍",
              href:"/",
            },
            {
              title: "插件下载",
              href:"/extension",
            },
          ],
        },
        {
          title: "关于我们",
          list: [
            {
              title: "联系我们",
              href:"/",
            },
            {
              title: "关于我们",
              href:"/about",
            },
            {
              title: "投诉建议",
              href:"/complain",
            },
          ],
        },
        {
          title: "更多产品",
          list: [
            {
              title: "浏览器插件",
              href:"/extension",
            },
            {
              title: "土豆查",
              href:"/",
            },
          ],
        },
      ],
      dLinks: [
        {
          title: "土豆查数据",
          link: "//wwww.tudoucha.com",
        },
        {
          title: "土豆查浏览器插件",
          link: "//wwww.tudoucha.com/extension",
        },
        {
          title: "抖音短视频",
          link: "//wwww.douyin.com",
        },
      ],
    };
  },
  methods: {
    onChangeLink(it) {
      if (it.router) {
        this.$router.push(it.router);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #474b54;
.footers {
  border-top: 1px solid $bg;
  margin-top: 40px;
  padding: 30px;
  text-align: center;
  a{
    font-size: 14px;
    color:#868c99;
  }
}
.rightLine {
  position: relative;
  &::after {
    position: absolute;
    display: block;
    height: 100%;
    width: 1px;
    right: 0;
    top: 0;
    content: "";
    background: $bg;
  }
}
.title {
  text-align: center;
}
.code {
  width: 130px;
  height: 130px;
  margin: 0 auto 10px;
  background: #fff;
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
  }
}
ul {
  list-style: none;
}
a {
  color: #666b75;
  font-size: 14px;
  &:hover {
    color: #8d95a4;
  }
}
.gap {
  display: flex;
  flex-flow: column nowrap;
  &>*{
    margin-bottom: 15px;
    &:last-child{
      margin: 0;
    }
  }
}
h3 {
  font-size: 18px;
}
.footer {
  // height: 320px;
  background: #2e323a;
  color: #868c99;
  padding: 45px 0 0;
  min-width: var(--au-width);
}
</style>