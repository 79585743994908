<template>
  <div class="head">
      <div class="hd">
        <div class="title">{{title}}</div>
      </div>
      <div class="bg">{{desc}}</div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:"公司简介"
        },
        desc:{
            type:String,
            default:"About Us"
        }
    }
}
</script>

<style lang="scss" scoped>
.head{
    position: relative;
    .bg{
        font-size: 120px;
        color: #fbfbfb;
        font-weight: bold;
        text-transform:uppercase;
    }
    .hd{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .title{
        color: #333333;
        font-weight: bold;
        font-size: 32px;
    }
}
</style>