<template>
  <div class="page">
    <!-- 顶部简介 -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="bg6">
            <div class="box">
              <div class="info-box">
                <transition name="fadeUp" :duration="3000" appear>
                  <div class="fadeUp" v-show="swiperIndex == 0">
                    <h2>四川土豆查网络科技有限公司</h2>
                    <p>
                      四川土豆查网络科技有限公司（旗下软件土豆查工具），是一家电商数据分析服务公司，生意参谋指数转化插件形式的首创者，
                      为淘宝商家提供生意参谋指数换算分析和数据计算导出的插件、致力于帮助商家实现数据快速分析，节省大量时间。<br />
                      公司围绕抖音客户需求，推出无水印下载、数据优化、评论下载分析等产品。
                      我们有着10年的短视频经验，深知短视频的需求，不断的创新出短视频辅助类插件！让客户更高效的进行短视频数据分析！
                    </p>
                    <a href="javascript:;">联系我们</a>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="lastSwiperScoll" ref="lastSwiper">
            <div class="bg7">
              <div class="box2">
                <div class="info-box">
                  <transition name="fadeUp" :duration="3000" appear>
                    <div class="fadeUp" v-show="swiperIndex == 1">
                      <h2>四川土豆查网络科技有限公司</h2>
                      <p>
                        我们相信，品牌成长的过程，就是与客户肩并肩迈向成功的过程
                      </p>
                      <div class="cart">
                        <div
                          v-for="(it, index) in list"
                          :key="index"
                          :class="{
                            top: it.top,
                            'cart-box': it != '-',
                            'dot-item': it == '-',
                          }"
                        >
                          <template v-if="it != '-'">
                            <img class="img-icon" :src="it.img" />
                            <h3 class="title">{{ it.title }}</h3>
                            <p class="desc">
                              {{ it.desc }}
                            </p>
                          </template>
                        </div>
                      </div>
                      <div class="infos">
                        土豆查，数据查询、流量分析、账户管理。
                      </div>
                      <div class="link">www.tudoucha.com</div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <!-- 底部 -->
            <au-footer></au-footer>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/assets/libs/swiper-bundle.min.js";
import "@/assets/libs/swiper-bundle.min.css";
import auAboutHeader from "./components/about/auAboutHeader.vue";
import AuFooter from "./components/auFooter.vue";
export default {
  components: { auAboutHeader, AuFooter },
  data() {
    return {
      mySwiper: null,
      swiperIndex: 0,
      lastSwiperY:0,
      list: [
        {
          title: "十年磨一剑",
          desc: "我们有着十年短视频经验，深知客户的痛点和难点，旨在打造高效数据分析工具。",
          img: require("@/assets/img/icon_touying.png"),
        },
        "-",
        {
          title: "短视频火爆",
          desc: "近两年短视频的迅速爆火，土豆查工具精准分析流量，一夜红遍短视频圈。",
          img: require("@/assets/img/icon_leida.png"),
          top: true,
        },
        "-",
        {
          title: "电商平台覆盖",
          desc: "如今土豆查工具已覆盖抖音、快手系平台，更多工具正在研发中。",
          img: require("@/assets/img/icon_duobianxing.png"),
        },
        "-",
        {
          title: "2021再出发",
          desc: "新的一年里土豆查工具将加大研发力度，继续陪伴广大短视频伙伴前行，共同进步！",
          img: require("@/assets/img/icon_men.png"),
          top: true,
        },
      ],
    };
  },
  methods: {
    onLastSwiperScroll(e) {
      const sY=this.$refs.lastSwiper.scrollTop;
      const y=e.deltaY;
      if(sY==0 && y<0){
        return;
      }else{
        //设置滚动条位置
        this.$refs.lastSwiper.scrollTo(0,sY+y);
      }
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        window.event.returnValue = false;
        window.event.cancelBubble = true;
      }
      return false;
    },
    slideChange(swiper) {
      this.swiperIndex = swiper.realIndex;
      if (swiper.realIndex > 0) {
        window.auHeader.skinDefault = true;
      } else {
        window.auHeader.skinDefault = false;
      }
    },
    loadSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "vertical",
        mousewheel: {
          releaseOnEdges: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChangeTransitionStart: this.slideChange,
        },
      });
    },
  },
  mounted() {
    this.loadSwiper();
    this.$refs.lastSwiper.addEventListener("mousewheel", this.onLastSwiperScroll);
    // this.$refs.lastSwiper.addEventListener("wheel", this.onLastSwiperScroll);
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.box {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info-box {
    position: relative;
    left: -106px;
    width: 670px;
    padding-left: 136px;
    padding-top: 86px;
    background: url(../../assets/img/bg_text.png) no-repeat 0 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    h2 {
      position: relative;
      padding-bottom: 28px;
      font-weight: 700;
      color: #fff;
      font-size: 42px;
      &::after {
        content: "";
        position: absolute;
        left: 4px;
        bottom: 0;
        width: 60px;
        height: 4px;
        background: #f6f8f9;
      }
    }
    p {
      margin-top: 46px;
      font-size: 16px;
      color: #fff;
      line-height: 28px;
      text-align: justify;
    }
    a {
      display: flex;
      margin-top: 40px;
      width: 160px;
      border-radius: 0;
      color: #050b41;
      font-size: 16px;
      height: 46px;
      background-color: #f6f8fb;
      border: 1px solid #f6f8fb;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 0 20px -3px hsl(0deg 0% 100% / 30%);
        transform: translateY(-6px);
      }
    }
  }
}
.box2 {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 60px;
  .link {
    color: #596780;
    margin-top: 20px;
    letter-spacing: 4px;
  }
  .infos {
    position: relative;
    width: 980px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 14px;
    color: #596780;
    line-height: 30px;
    letter-spacing: 8px;
    margin-top: 90px;
  }
  h2 {
    position: relative;
    width: 100%;
    line-height: 76px;
    font-size: 32px;
    text-align: center;
  }
  p {
    font-size: 18px;
    color: #9da0ad;
    margin-top: 20px;
  }
  .cart {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 120px;
    .dot-item {
      position: relative;
      top: 80px;
      -ms-flex-item-align: start;
      align-self: flex-start;
      display: inline-block;
      width: 34px;
      height: 12px;
      background: url("../../assets/img/dot_bg.png") no-repeat 50%;
      background-size: cover;
    }
    .cart-box {
      width: 260px;
      height: 266px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: 0 7px 6px 0 rgb(131 148 173 / 12%);
      border-radius: 2px;
      transition: all 0.3s;
      box-sizing: border-box;
      &:hover {
        transform: translateY(-10px);
      }
      &.top {
        position: relative;
        top: -62px;
      }
      img {
        width: 40px;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 16px;
        color: #3c4254;
        font-weight: 700;
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #3c4254;
        line-height: 26px;
      }
    }
  }
}

.lastSwiperScoll {
  height: 100%;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar{
    width: 0;
  }
}
.swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #03093b, #0a1954);
}
.bg6 {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/bg6.png") no-repeat 50%;
}
.bg7 {
  width: 100%;
  min-width: 1200px;
  padding-top: 60px;
  height: 100%;
  background-size: 100%;
  background: url("../../assets/img/bg7.png") no-repeat top #f6f8f9;
}
.swiper-container {
  height: 100vh;
  width: 100%;
  margin-top: -60px;
  // overflow: hidden;
}
.swiper-pagination {
  right: 17px;
}
::v-deep {
  .swiper-pagination-bullet {
    height: 4px;
    width: 4px;
    border: 0;
    border-radius: 50%;
    background-color: #bfbfbf;
    margin: 14px 0 !important;
  }
  .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    box-shadow: 0 0 4px 0 rgb(5 88 246 / 80%);
    background-color: #0558f6;
    position: relative;
    left: -4px;
  }
}
</style>
<style lang="scss">
html,
body {
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>

